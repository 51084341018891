import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const ChangePwdPage = lazy(() => import('../modules/apps/changePwd-management/ChangePwdPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CategoriesPage = lazy(() => import('../modules/apps/category-management/CategoriesPage'))
  const ItemsPage = lazy(() => import('../modules/apps/items-management/ItemsPage'))
  const SubCategoriesPage = lazy(() => import('../modules/apps/subcategory-management/SubCategoriesPage'))
  const ConcessionairesPage = lazy(() => import('../modules/apps/concessionaire-management/ConcessionairesPage'))
  const CategoriesBPage = lazy(() => import('../modules/apps/categoryB-management/CategoriesBPage'))
  const ItemsBPage = lazy(() => import('../modules/apps/itemsB-management/ItemsBPage'))
  const SubCategoriesBPage = lazy(() => import('../modules/apps/subcategoryB-management/SubCategoriesBPage'))
  const ImportPage = lazy(() => import('../modules/apps/import-management/ImportPage'))
  const ImportPageB = lazy(() => import('../modules/apps/importB-management/ImportPageB'))
  const ImportPageC = lazy(() => import('../modules/apps/importC-management/ImportPage'))
  const ListPage = lazy(() => import('../modules/apps/list-management/ListPage'))
  const ListPageB = lazy(() => import('../modules/apps/listB-management/ListPageB'))
  const DownloadPlanillaC = lazy(() => import('../modules/apps/downloadC-management/DownloadPage'))
  const DownloadPlanillaA = lazy(() => import('../modules/apps/downloadA-management/DownloadPage'))
  const DownloadPlanillaB = lazy(() => import('../modules/apps/downloadB-management/DownloadPage'))
  const CriterionPage = lazy(() => import('../modules/apps/criterion-management/CriterionPage'))
  const ReportsPage = lazy(() => import('../modules/apps/reports/ReportsPage'))
  const PercentagePage = lazy(() => import('../modules/apps/percentageCriterion-management/PercentagePage'))
  const GraphicsPage = lazy(() => import('../modules/apps/graphics-management/GraphicsPage'))
  const GraphicsPage2 = lazy(() => import('../modules/apps/graphics2-management/GraphicsPage2'))
  const GraphicScPage = lazy(() => import('../modules/apps/graphicSc-management/GraphicScPage'))
  const GraphicScConPage = lazy(() => import('../modules/apps/graphicScCon-management/GraphicScConPage'))
  const GraphicInterviewsPage = lazy(() => import('../modules/apps/graphicInterviews-management/GraphicInterviewsPage'))
  const GraphicCompareInterviewsPage = lazy(() => import('../modules/apps/graphicCompareInterview-management/GraphicCompareInterviewPage'))
  const GraphicInterviewsPosventaPage = lazy(() => import('../modules/apps/graphicInterviewsPosventa-management/GraphicInterviewsPage'))
  const GraphicCompareInterviewsPosventaPage = lazy(() => import('../modules/apps/graphicCompareInterviewPosventa-management/GraphicCompareInterviewPage'))
 
 

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/changePwd-management/*'
          element={
            <SuspensedView>
              <ChangePwdPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/list-management/*'
          element={
            <SuspensedView>
              <ListPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/listB-management/*'
          element={
            <SuspensedView>
              <ListPageB />
            </SuspensedView>
          }
        />
        <Route
          path='apps/category-management/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/subcategory-management/*'
          element={
            <SuspensedView>
              <SubCategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/items-management/*'
          element={
            <SuspensedView>
              <ItemsPage />
            </SuspensedView>
          }
        />
          <Route
          path='apps/concessionaire-management/*'
          element={
            <SuspensedView>
              <ConcessionairesPage />
            </SuspensedView>
          }
        />

          <Route
          path='apps/subcategoryB-management/*'
          element={
            <SuspensedView>
              <SubCategoriesBPage />
            </SuspensedView>
          }
        />
         <Route
          path='apps/categoryB-management/*'
          element={
            <SuspensedView>
              <CategoriesBPage />
            </SuspensedView>
          }
        /> 
         <Route
          path='apps/percentageCriterion-management/*'
          element={
            <SuspensedView>
              <PercentagePage />
            </SuspensedView>
          }
        /> 
          <Route
          path='apps/itemsB-management/*'
          element={
            <SuspensedView>
              <ItemsBPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='apps/import-management/*'
          element={
            <SuspensedView>
              <ImportPage />
            </SuspensedView>
          }
          />
          <Route
          path='apps/importB-management/*'
          element={
            <SuspensedView>
              <ImportPageB />
            </SuspensedView>
          }
          />
          <Route
          path='apps/downloadC-management/*'
          element={
            <SuspensedView>
              <DownloadPlanillaC />
            </SuspensedView>
          }
          />
          <Route
          path='apps/importC-management/*'
          element={
            <SuspensedView>
              <ImportPageC />
            </SuspensedView>
          }
          />

          <Route
          path='apps/downloadA-management/*'
          element={
            <SuspensedView>
              <DownloadPlanillaA />
            </SuspensedView>
          }
          />
          <Route
          path='apps/downloadB-management/*'
          element={
            <SuspensedView>
              <DownloadPlanillaB />
            </SuspensedView>
          }
          />
          <Route
          path='apps/criterion-management/*'
          element={
            <SuspensedView>
              <CriterionPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/import-management/*'
          element={
            <SuspensedView>
              <ImportPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/reports/*'
          element={
            <SuspensedView>
              <ReportsPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphics-management/*'
          element={
            <SuspensedView>
              <GraphicsPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphics2-management/*'
          element={
            <SuspensedView>
              <GraphicsPage2/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicSc-management/*'
          element={
            <SuspensedView>
              <GraphicScPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicScCon-management/*'
          element={
            <SuspensedView>
              <GraphicScConPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicInterviews-management/*'
          element={
            <SuspensedView>
              <GraphicInterviewsPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicCompareInterview-management/*'
          element={
            <SuspensedView>
              <GraphicCompareInterviewsPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicInterviewsPosventa-management/*'
          element={
            <SuspensedView>
              <GraphicInterviewsPosventaPage/>
            </SuspensedView>
          }
          />
          <Route
          path='apps/graphicCompareInterviewPosventa-management/*'
          element={
            <SuspensedView>
              <GraphicCompareInterviewsPosventaPage/>
            </SuspensedView>
          }
          />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
